*, ::before, ::after {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x:  ;
  --tw-pan-y:  ;
  --tw-pinch-zoom:  ;
  --tw-scroll-snap-strictness: proximity;
  --tw-gradient-from-position:  ;
  --tw-gradient-via-position:  ;
  --tw-gradient-to-position:  ;
  --tw-ordinal:  ;
  --tw-slashed-zero:  ;
  --tw-numeric-figure:  ;
  --tw-numeric-spacing:  ;
  --tw-numeric-fraction:  ;
  --tw-ring-inset:  ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: rgb(59 130 246 / 0.5);
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur:  ;
  --tw-brightness:  ;
  --tw-contrast:  ;
  --tw-grayscale:  ;
  --tw-hue-rotate:  ;
  --tw-invert:  ;
  --tw-saturate:  ;
  --tw-sepia:  ;
  --tw-drop-shadow:  ;
  --tw-backdrop-blur:  ;
  --tw-backdrop-brightness:  ;
  --tw-backdrop-contrast:  ;
  --tw-backdrop-grayscale:  ;
  --tw-backdrop-hue-rotate:  ;
  --tw-backdrop-invert:  ;
  --tw-backdrop-opacity:  ;
  --tw-backdrop-saturate:  ;
  --tw-backdrop-sepia:  
}

::-webkit-backdrop {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x:  ;
  --tw-pan-y:  ;
  --tw-pinch-zoom:  ;
  --tw-scroll-snap-strictness: proximity;
  --tw-gradient-from-position:  ;
  --tw-gradient-via-position:  ;
  --tw-gradient-to-position:  ;
  --tw-ordinal:  ;
  --tw-slashed-zero:  ;
  --tw-numeric-figure:  ;
  --tw-numeric-spacing:  ;
  --tw-numeric-fraction:  ;
  --tw-ring-inset:  ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: rgb(59 130 246 / 0.5);
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur:  ;
  --tw-brightness:  ;
  --tw-contrast:  ;
  --tw-grayscale:  ;
  --tw-hue-rotate:  ;
  --tw-invert:  ;
  --tw-saturate:  ;
  --tw-sepia:  ;
  --tw-drop-shadow:  ;
  --tw-backdrop-blur:  ;
  --tw-backdrop-brightness:  ;
  --tw-backdrop-contrast:  ;
  --tw-backdrop-grayscale:  ;
  --tw-backdrop-hue-rotate:  ;
  --tw-backdrop-invert:  ;
  --tw-backdrop-opacity:  ;
  --tw-backdrop-saturate:  ;
  --tw-backdrop-sepia:  
}

::backdrop {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x:  ;
  --tw-pan-y:  ;
  --tw-pinch-zoom:  ;
  --tw-scroll-snap-strictness: proximity;
  --tw-gradient-from-position:  ;
  --tw-gradient-via-position:  ;
  --tw-gradient-to-position:  ;
  --tw-ordinal:  ;
  --tw-slashed-zero:  ;
  --tw-numeric-figure:  ;
  --tw-numeric-spacing:  ;
  --tw-numeric-fraction:  ;
  --tw-ring-inset:  ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: rgb(59 130 246 / 0.5);
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur:  ;
  --tw-brightness:  ;
  --tw-contrast:  ;
  --tw-grayscale:  ;
  --tw-hue-rotate:  ;
  --tw-invert:  ;
  --tw-saturate:  ;
  --tw-sepia:  ;
  --tw-drop-shadow:  ;
  --tw-backdrop-blur:  ;
  --tw-backdrop-brightness:  ;
  --tw-backdrop-contrast:  ;
  --tw-backdrop-grayscale:  ;
  --tw-backdrop-hue-rotate:  ;
  --tw-backdrop-invert:  ;
  --tw-backdrop-opacity:  ;
  --tw-backdrop-saturate:  ;
  --tw-backdrop-sepia:  
}

/* Preflight */

.container {
  width: 100%
}

@media (min-width: 640px) {
  .container {
    max-width: 640px
  }
}

@media (min-width: 768px) {
  .container {
    max-width: 768px
  }
}

@media (min-width: 1024px) {
  .container {
    max-width: 1024px
  }
}

@media (min-width: 1280px) {
  .container {
    max-width: 1280px
  }
}

@media (min-width: 1536px) {
  .container {
    max-width: 1536px
  }
}

/* @tailwind components; */

.visible {
  visibility: visible
}

.collapse {
  visibility: collapse
}

.fixed {
  position: fixed
}

.absolute {
  position: absolute
}

.relative {
  position: relative
}

.bottom-0 {
  bottom: 0px
}

.bottom-\[20px\] {
  bottom: 20px
}

.top-\[10px\] {
  top: 10px
}

.top-\[120px\] {
  top: 120px
}

.z-10 {
  z-index: 10
}

.col-span-4 {
  grid-column: span 4 / span 4
}

.m-0 {
  margin: 0px
}

.m-2 {
  margin: 0.5rem
}

.mx-2 {
  margin-left: 0.5rem;
  margin-right: 0.5rem
}

.mx-4 {
  margin-left: 1rem;
  margin-right: 1rem
}

.my-0 {
  margin-top: 0px;
  margin-bottom: 0px
}

.my-1 {
  margin-top: 0.25rem;
  margin-bottom: 0.25rem
}

.my-2 {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem
}

.my-4 {
  margin-top: 1rem;
  margin-bottom: 1rem
}

.-ml-2 {
  margin-left: -0.5rem
}

.-mt-24 {
  margin-top: -6rem
}

.-mt-4 {
  margin-top: -1rem
}

.-mt-\[\.4rem\] {
  margin-top: -.4rem
}

.mb-1 {
  margin-bottom: 0.25rem
}

.mb-2 {
  margin-bottom: 0.5rem
}

.mb-3 {
  margin-bottom: 0.75rem
}

.mb-4 {
  margin-bottom: 1rem
}

.mb-6 {
  margin-bottom: 1.5rem
}

.mb-\[10px\] {
  margin-bottom: 10px
}

.ml-1 {
  margin-left: 0.25rem
}

.ml-2 {
  margin-left: 0.5rem
}

.ml-4 {
  margin-left: 1rem
}

.ml-6 {
  margin-left: 1.5rem
}

.ml-\[-100px\] {
  margin-left: -100px
}

.ml-\[100px\] {
  margin-left: 100px
}

.ml-auto {
  margin-left: auto
}

.mr-1 {
  margin-right: 0.25rem
}

.mr-10 {
  margin-right: 2.5rem
}

.mr-2 {
  margin-right: 0.5rem
}

.mr-3 {
  margin-right: 0.75rem
}

.mr-auto {
  margin-right: auto
}

.mt-1 {
  margin-top: 0.25rem
}

.mt-2 {
  margin-top: 0.5rem
}

.mt-3 {
  margin-top: 0.75rem
}

.mt-4 {
  margin-top: 1rem
}

.mt-5 {
  margin-top: 1.25rem
}

.box-border {
  box-sizing: border-box
}

.block {
  display: block
}

.inline-block {
  display: inline-block
}

.inline {
  display: inline
}

.flex {
  display: flex
}

.table {
  display: table
}

.grid {
  display: grid
}

.hidden {
  display: none
}

.h-10 {
  height: 2.5rem
}

.h-4 {
  height: 1rem
}

.h-6 {
  height: 1.5rem
}

.h-\[120px\] {
  height: 120px
}

.h-\[130px\] {
  height: 130px
}

.h-\[13px\] {
  height: 13px
}

.h-\[16px\] {
  height: 16px
}

.h-\[200px\] {
  height: 200px
}

.h-\[20px\] {
  height: 20px
}

.h-\[230px\] {
  height: 230px
}

.h-\[300px\] {
  height: 300px
}

.h-\[375px\] {
  height: 375px
}

.h-\[400px\] {
  height: 400px
}

.h-\[420px\] {
  height: 420px
}

.h-\[426px\] {
  height: 426px
}

.h-\[64px\] {
  height: 64px
}

.h-\[8px\] {
  height: 8px
}

.h-auto {
  height: auto
}

.h-full {
  height: 100%
}

.h-screen {
  height: 100vh
}

.max-h-\[95vh\] {
  max-height: 95vh
}

.min-h-\[140px\] {
  min-height: 140px
}

.min-h-\[150px\] {
  min-height: 150px
}

.min-h-\[170px\] {
  min-height: 170px
}

.min-h-min {
  min-height: -webkit-min-content;
  min-height: min-content
}

.w-1\/4 {
  width: 25%
}

.w-16 {
  width: 4rem
}

.w-4 {
  width: 1rem
}

.w-6 {
  width: 1.5rem
}

.w-\[1px\] {
  width: 1px
}

.w-\[300px\] {
  width: 300px
}

.w-\[40\%\] {
  width: 40%
}

.w-\[500px\] {
  width: 500px
}

.w-\[70px\] {
  width: 70px
}

.w-\[8px\] {
  width: 8px
}

.w-auto {
  width: auto
}

.w-full {
  width: 100%
}

.min-w-\[1130px\] {
  min-width: 1130px
}

.min-w-\[300px\] {
  min-width: 300px
}

.min-w-\[390px\] {
  min-width: 390px
}

.min-w-\[400px\] {
  min-width: 400px
}

.min-w-\[500px\] {
  min-width: 500px
}

.min-w-\[80px\] {
  min-width: 80px
}

.min-w-fit {
  min-width: -webkit-fit-content;
  min-width: -moz-fit-content;
  min-width: fit-content
}

.min-w-max {
  min-width: -webkit-max-content;
  min-width: max-content
}

.max-w-\[400px\] {
  max-width: 400px
}

.max-w-\[600px\] {
  max-width: 600px
}

.transform {
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))
}

@keyframes spin {
  to {
    transform: rotate(360deg)
  }
}

.animate-spin {
  animation: spin 1s linear infinite
}

.cursor-not-allowed {
  cursor: not-allowed
}

.cursor-pointer {
  cursor: pointer
}

.cursor-text {
  cursor: text
}

.select-none {
  -webkit-user-select: none;
          user-select: none
}

.resize {
  resize: both
}

.grid-flow-col {
  grid-auto-flow: column
}

.grid-cols-12 {
  grid-template-columns: repeat(12, minmax(0, 1fr))
}

.grid-cols-2 {
  grid-template-columns: repeat(2, minmax(0, 1fr))
}

.grid-rows-1 {
  grid-template-rows: repeat(1, minmax(0, 1fr))
}

.flex-row {
  flex-direction: row
}

.flex-row-reverse {
  flex-direction: row-reverse
}

.flex-col {
  flex-direction: column
}

.flex-wrap {
  flex-wrap: wrap
}

.flex-nowrap {
  flex-wrap: nowrap
}

.place-items-center {
  place-items: center
}

.content-center {
  align-content: center
}

.items-end {
  align-items: flex-end
}

.items-center {
  align-items: center
}

.justify-start {
  justify-content: flex-start
}

.justify-end {
  justify-content: flex-end
}

.justify-center {
  justify-content: center
}

.justify-between {
  justify-content: space-between
}

.gap-2 {
  gap: 0.5rem
}

.gap-3 {
  gap: 0.75rem
}

.gap-4 {
  gap: 1rem
}

.gap-\[2px\] {
  gap: 2px
}

.space-x-1 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(0.25rem * var(--tw-space-x-reverse));
  margin-left: calc(0.25rem * calc(1 - var(--tw-space-x-reverse)))
}

.overflow-auto {
  overflow: auto
}

.overflow-hidden {
  overflow: hidden
}

.overflow-y-auto {
  overflow-y: auto
}

.overflow-x-hidden {
  overflow-x: hidden
}

.overflow-y-scroll {
  overflow-y: scroll
}

.truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap
}

.text-ellipsis {
  text-overflow: ellipsis
}

.whitespace-nowrap {
  white-space: nowrap
}

.break-normal {
  overflow-wrap: normal;
  word-break: normal
}

.rounded {
  border-radius: 0.25rem
}

.rounded-full {
  border-radius: 9999px
}

.rounded-lg {
  border-radius: 0.5rem
}

.rounded-md {
  border-radius: 0.375rem
}

.rounded-none {
  border-radius: 0px
}

.rounded-sm {
  border-radius: 0.125rem
}

.rounded-xl {
  border-radius: 0.75rem
}

.rounded-t-lg {
  border-top-left-radius: 0.5rem;
  border-top-right-radius: 0.5rem
}

.rounded-bl-lg {
  border-bottom-left-radius: 0.5rem
}

.rounded-br-lg {
  border-bottom-right-radius: 0.5rem
}

.border {
  border-width: 1px
}

.border-0 {
  border-width: 0px
}

.border-b {
  border-bottom-width: 1px
}

.border-b-2 {
  border-bottom-width: 2px
}

.border-r {
  border-right-width: 1px
}

.border-t {
  border-top-width: 1px
}

.border-solid {
  border-style: solid
}

.border-dashed {
  border-style: dashed
}

.border-blue-600 {
  --tw-border-opacity: 1;
  border-color: rgb(37 99 235 / var(--tw-border-opacity))
}

.border-gray-200 {
  --tw-border-opacity: 1;
  border-color: rgb(229 231 235 / var(--tw-border-opacity))
}

.border-gray-300 {
  --tw-border-opacity: 1;
  border-color: rgb(209 213 219 / var(--tw-border-opacity))
}

.border-gray-50 {
  --tw-border-opacity: 1;
  border-color: rgb(249 250 251 / var(--tw-border-opacity))
}

.border-orange-600 {
  --tw-border-opacity: 1;
  border-color: rgb(234 88 12 / var(--tw-border-opacity))
}

.border-opacity-0 {
  --tw-border-opacity: 0
}

.bg-\[\#173071\] {
  --tw-bg-opacity: 1;
  background-color: rgb(23 48 113 / var(--tw-bg-opacity))
}

.bg-\[\#D49900\] {
  --tw-bg-opacity: 1;
  background-color: rgb(212 153 0 / var(--tw-bg-opacity))
}

.bg-\[\#F0F3F6\] {
  --tw-bg-opacity: 1;
  background-color: rgb(240 243 246 / var(--tw-bg-opacity))
}

.bg-blue-600 {
  --tw-bg-opacity: 1;
  background-color: rgb(37 99 235 / var(--tw-bg-opacity))
}

.bg-dodgerblue {
  --tw-bg-opacity: 1;
  background-color: rgb(30 144 255 / var(--tw-bg-opacity))
}

.bg-goods {
  --tw-bg-opacity: 1;
  background-color: rgb(192 232 241 / var(--tw-bg-opacity))
}

.bg-gray-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(243 244 246 / var(--tw-bg-opacity))
}

.bg-gray-200 {
  --tw-bg-opacity: 1;
  background-color: rgb(229 231 235 / var(--tw-bg-opacity))
}

.bg-gray-400 {
  --tw-bg-opacity: 1;
  background-color: rgb(156 163 175 / var(--tw-bg-opacity))
}

.bg-gray-50 {
  --tw-bg-opacity: 1;
  background-color: rgb(249 250 251 / var(--tw-bg-opacity))
}

.bg-green-900 {
  --tw-bg-opacity: 1;
  background-color: rgb(106 229 63 / var(--tw-bg-opacity))
}

.bg-mainOrange {
  --tw-bg-opacity: 1;
  background-color: rgb(238 89 25 / var(--tw-bg-opacity))
}

.bg-mantanee-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(230 232 237 / var(--tw-bg-opacity))
}

.bg-memo-today {
  --tw-bg-opacity: 1;
  background-color: rgb(26 189 23 / var(--tw-bg-opacity))
}

.bg-memo-tomorrow {
  --tw-bg-opacity: 1;
  background-color: rgb(233 172 16 / var(--tw-bg-opacity))
}

.bg-memo-urgent {
  --tw-bg-opacity: 1;
  background-color: rgb(245 30 30 / var(--tw-bg-opacity))
}

.bg-move {
  --tw-bg-opacity: 1;
  background-color: rgb(192 232 241 / var(--tw-bg-opacity))
}

.bg-orange-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(255 102 0 / var(--tw-bg-opacity))
}

.bg-orange-900 {
  --tw-bg-opacity: 1;
  background-color: rgb(248 154 32 / var(--tw-bg-opacity))
}

.bg-red-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(255 0 0 / var(--tw-bg-opacity))
}

.bg-red-600 {
  --tw-bg-opacity: 1;
  background-color: rgb(220 38 38 / var(--tw-bg-opacity))
}

.bg-status-acc {
  --tw-bg-opacity: 1;
  background-color: rgb(9 131 58 / var(--tw-bg-opacity))
}

.bg-status-all {
  --tw-bg-opacity: 1;
  background-color: rgb(237 125 49 / var(--tw-bg-opacity))
}

.bg-status-canc {
  --tw-bg-opacity: 1;
  background-color: rgb(211 2 40 / var(--tw-bg-opacity))
}

.bg-status-default {
  --tw-bg-opacity: 1;
  background-color: rgb(237 125 49 / var(--tw-bg-opacity))
}

.bg-status-del {
  --tw-bg-opacity: 1;
  background-color: rgb(169 106 11 / var(--tw-bg-opacity))
}

.bg-status-dispt {
  --tw-bg-opacity: 1;
  background-color: rgb(211 2 40 / var(--tw-bg-opacity))
}

.bg-status-edi {
  --tw-bg-opacity: 1;
  background-color: rgb(0 0 0 / var(--tw-bg-opacity))
}

.bg-status-hold {
  --tw-bg-opacity: 1;
  background-color: rgb(156 161 172 / var(--tw-bg-opacity))
}

.bg-status-invc {
  --tw-bg-opacity: 1;
  background-color: rgb(255 122 0 / var(--tw-bg-opacity))
}

.bg-status-new {
  --tw-bg-opacity: 1;
  background-color: rgb(212 178 43 / var(--tw-bg-opacity))
}

.bg-status-ofd {
  --tw-bg-opacity: 1;
  background-color: rgb(68 187 116 / var(--tw-bg-opacity))
}

.bg-status-ofl {
  --tw-bg-opacity: 1;
  background-color: rgb(111 89 72 / var(--tw-bg-opacity))
}

.bg-status-pak {
  --tw-bg-opacity: 1;
  background-color: rgb(255 122 0 / var(--tw-bg-opacity))
}

.bg-status-pln {
  --tw-bg-opacity: 1;
  background-color: rgb(247 53 219 / var(--tw-bg-opacity))
}

.bg-status-pod {
  --tw-bg-opacity: 1;
  background-color: rgb(4 95 128 / var(--tw-bg-opacity))
}

.bg-status-pup {
  --tw-bg-opacity: 1;
  background-color: rgb(68 187 116 / var(--tw-bg-opacity))
}

.bg-status-qte {
  --tw-bg-opacity: 1;
  background-color: rgb(167 119 47 / var(--tw-bg-opacity))
}

.bg-status-rdy {
  --tw-bg-opacity: 1;
  background-color: rgb(177 40 231 / var(--tw-bg-opacity))
}

.bg-status-rfp {
  --tw-bg-opacity: 1;
  background-color: rgb(254 59 60 / var(--tw-bg-opacity))
}

.bg-status-spl {
  --tw-bg-opacity: 1;
  background-color: rgb(123 123 123 / var(--tw-bg-opacity))
}

.bg-status-upk {
  --tw-bg-opacity: 1;
  background-color: rgb(131 120 111 / var(--tw-bg-opacity))
}

.bg-status-upl {
  --tw-bg-opacity: 1;
  background-color: rgb(9 131 58 / var(--tw-bg-opacity))
}

.bg-status-vfed {
  --tw-bg-opacity: 1;
  background-color: rgb(0 150 255 / var(--tw-bg-opacity))
}

.bg-status-web {
  background-color: #464646a4
}

.bg-transparent {
  background-color: transparent
}

.bg-white {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity))
}

.bg-yellow-200 {
  --tw-bg-opacity: 1;
  background-color: rgb(254 240 138 / var(--tw-bg-opacity))
}

.bg-yellow-300 {
  --tw-bg-opacity: 1;
  background-color: rgb(253 224 71 / var(--tw-bg-opacity))
}

.bg-opacity-\[\.15\] {
  --tw-bg-opacity: .15
}

.p-0 {
  padding: 0px
}

.p-1 {
  padding: 0.25rem
}

.p-2 {
  padding: 0.5rem
}

.p-3 {
  padding: 0.75rem
}

.p-4 {
  padding: 1rem
}

.px-1 {
  padding-left: 0.25rem;
  padding-right: 0.25rem
}

.px-12 {
  padding-left: 3rem;
  padding-right: 3rem
}

.px-2 {
  padding-left: 0.5rem;
  padding-right: 0.5rem
}

.px-3 {
  padding-left: 0.75rem;
  padding-right: 0.75rem
}

.px-4 {
  padding-left: 1rem;
  padding-right: 1rem
}

.px-6 {
  padding-left: 1.5rem;
  padding-right: 1.5rem
}

.px-8 {
  padding-left: 2rem;
  padding-right: 2rem
}

.px-\[6px\] {
  padding-left: 6px;
  padding-right: 6px
}

.py-1 {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem
}

.py-2 {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem
}

.py-3 {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem
}

.py-8 {
  padding-top: 2rem;
  padding-bottom: 2rem
}

.py-\[6px\] {
  padding-top: 6px;
  padding-bottom: 6px
}

.pb-0 {
  padding-bottom: 0px
}

.pb-2 {
  padding-bottom: 0.5rem
}

.pb-3 {
  padding-bottom: 0.75rem
}

.pb-4 {
  padding-bottom: 1rem
}

.pb-6 {
  padding-bottom: 1.5rem
}

.pb-\[64px\] {
  padding-bottom: 64px
}

.pl-1 {
  padding-left: 0.25rem
}

.pl-2 {
  padding-left: 0.5rem
}

.pl-4 {
  padding-left: 1rem
}

.pr-4 {
  padding-right: 1rem
}

.pt-4 {
  padding-top: 1rem
}

.text-left {
  text-align: left
}

.text-center {
  text-align: center
}

.text-right {
  text-align: right
}

.align-middle {
  vertical-align: middle
}

.text-2xl {
  font-size: 1.5rem;
  line-height: 2rem
}

.text-\[\.7rem\] {
  font-size: .7rem
}

.text-\[\.8rem\] {
  font-size: .8rem
}

.text-\[1\.2rem\] {
  font-size: 1.2rem
}

.text-\[12px\] {
  font-size: 12px
}

.text-\[14px\] {
  font-size: 14px
}

.text-\[18px\] {
  font-size: 18px
}

.text-\[20px\] {
  font-size: 20px
}

.text-\[26px\] {
  font-size: 26px
}

.text-lg {
  font-size: 1.125rem;
  line-height: 1.75rem
}

.text-sm {
  font-size: 0.875rem;
  line-height: 1.25rem
}

.text-xs {
  font-size: 0.75rem;
  line-height: 1rem
}

.font-bold {
  font-weight: 700
}

.font-medium {
  font-weight: 500
}

.font-normal {
  font-weight: 400
}

.font-semibold {
  font-weight: 600
}

.uppercase {
  text-transform: uppercase
}

.lowercase {
  text-transform: lowercase
}

.capitalize {
  text-transform: capitalize
}

.leading-5 {
  line-height: 1.25rem
}

.leading-\[1\.4rem\] {
  line-height: 1.4rem
}

.text-\[\#173071\] {
  --tw-text-opacity: 1;
  color: rgb(23 48 113 / var(--tw-text-opacity))
}

.text-\[\#D49900\] {
  --tw-text-opacity: 1;
  color: rgb(212 153 0 / var(--tw-text-opacity))
}

.text-action-iconDefault {
  --tw-text-opacity: 1;
  color: rgb(168 174 184 / var(--tw-text-opacity))
}

.text-black-100 {
  --tw-text-opacity: 1;
  color: rgb(125 124 131 / var(--tw-text-opacity))
}

.text-black-200 {
  --tw-text-opacity: 1;
  color: rgb(36 36 36 / var(--tw-text-opacity))
}

.text-black-800 {
  --tw-text-opacity: 1;
  color: rgb(168 174 184 / var(--tw-text-opacity))
}

.text-blue-500 {
  --tw-text-opacity: 1;
  color: rgb(59 130 246 / var(--tw-text-opacity))
}

.text-blue-600 {
  --tw-text-opacity: 1;
  color: rgb(37 99 235 / var(--tw-text-opacity))
}

.text-darkblue-100 {
  --tw-text-opacity: 1;
  color: rgb(0 0 139 / var(--tw-text-opacity))
}

.text-gray-200 {
  --tw-text-opacity: 1;
  color: rgb(229 231 235 / var(--tw-text-opacity))
}

.text-gray-300 {
  --tw-text-opacity: 1;
  color: rgb(209 213 219 / var(--tw-text-opacity))
}

.text-gray-400 {
  --tw-text-opacity: 1;
  color: rgb(156 163 175 / var(--tw-text-opacity))
}

.text-gray-500 {
  --tw-text-opacity: 1;
  color: rgb(107 114 128 / var(--tw-text-opacity))
}

.text-gray-600 {
  --tw-text-opacity: 1;
  color: rgb(75 85 99 / var(--tw-text-opacity))
}

.text-gray-700 {
  --tw-text-opacity: 1;
  color: rgb(55 65 81 / var(--tw-text-opacity))
}

.text-gray-900 {
  --tw-text-opacity: 1;
  color: rgb(17 24 39 / var(--tw-text-opacity))
}

.text-green-800 {
  --tw-text-opacity: 1;
  color: rgb(120 171 70 / var(--tw-text-opacity))
}

.text-mainOrange {
  --tw-text-opacity: 1;
  color: rgb(238 89 25 / var(--tw-text-opacity))
}

.text-mantanee-900 {
  --tw-text-opacity: 1;
  color: rgb(156 161 172 / var(--tw-text-opacity))
}

.text-memo-today {
  --tw-text-opacity: 1;
  color: rgb(26 189 23 / var(--tw-text-opacity))
}

.text-memo-tomorrow {
  --tw-text-opacity: 1;
  color: rgb(233 172 16 / var(--tw-text-opacity))
}

.text-memo-urgent {
  --tw-text-opacity: 1;
  color: rgb(245 30 30 / var(--tw-text-opacity))
}

.text-primary {
  --tw-text-opacity: 1;
  color: rgb(64 70 79 / var(--tw-text-opacity))
}

.text-red-100 {
  --tw-text-opacity: 1;
  color: rgb(255 0 0 / var(--tw-text-opacity))
}

.text-red-500 {
  --tw-text-opacity: 1;
  color: rgb(239 68 68 / var(--tw-text-opacity))
}

.text-red-900 {
  --tw-text-opacity: 1;
  color: rgb(243 44 44 / var(--tw-text-opacity))
}

.text-status-acc {
  --tw-text-opacity: 1;
  color: rgb(9 131 58 / var(--tw-text-opacity))
}

.text-status-all {
  --tw-text-opacity: 1;
  color: rgb(237 125 49 / var(--tw-text-opacity))
}

.text-status-canc {
  --tw-text-opacity: 1;
  color: rgb(211 2 40 / var(--tw-text-opacity))
}

.text-status-default {
  --tw-text-opacity: 1;
  color: rgb(237 125 49 / var(--tw-text-opacity))
}

.text-status-del {
  --tw-text-opacity: 1;
  color: rgb(169 106 11 / var(--tw-text-opacity))
}

.text-status-dispt {
  --tw-text-opacity: 1;
  color: rgb(211 2 40 / var(--tw-text-opacity))
}

.text-status-edi {
  --tw-text-opacity: 1;
  color: rgb(0 0 0 / var(--tw-text-opacity))
}

.text-status-hold {
  --tw-text-opacity: 1;
  color: rgb(156 161 172 / var(--tw-text-opacity))
}

.text-status-invc {
  --tw-text-opacity: 1;
  color: rgb(255 122 0 / var(--tw-text-opacity))
}

.text-status-new {
  --tw-text-opacity: 1;
  color: rgb(212 178 43 / var(--tw-text-opacity))
}

.text-status-ofd {
  --tw-text-opacity: 1;
  color: rgb(68 187 116 / var(--tw-text-opacity))
}

.text-status-ofl {
  --tw-text-opacity: 1;
  color: rgb(111 89 72 / var(--tw-text-opacity))
}

.text-status-pak {
  --tw-text-opacity: 1;
  color: rgb(255 122 0 / var(--tw-text-opacity))
}

.text-status-pln {
  --tw-text-opacity: 1;
  color: rgb(247 53 219 / var(--tw-text-opacity))
}

.text-status-pod {
  --tw-text-opacity: 1;
  color: rgb(4 95 128 / var(--tw-text-opacity))
}

.text-status-pup {
  --tw-text-opacity: 1;
  color: rgb(68 187 116 / var(--tw-text-opacity))
}

.text-status-qte {
  --tw-text-opacity: 1;
  color: rgb(167 119 47 / var(--tw-text-opacity))
}

.text-status-rdy {
  --tw-text-opacity: 1;
  color: rgb(177 40 231 / var(--tw-text-opacity))
}

.text-status-rfp {
  --tw-text-opacity: 1;
  color: rgb(254 59 60 / var(--tw-text-opacity))
}

.text-status-spl {
  --tw-text-opacity: 1;
  color: rgb(123 123 123 / var(--tw-text-opacity))
}

.text-status-upk {
  --tw-text-opacity: 1;
  color: rgb(131 120 111 / var(--tw-text-opacity))
}

.text-status-upl {
  --tw-text-opacity: 1;
  color: rgb(9 131 58 / var(--tw-text-opacity))
}

.text-status-vfed {
  --tw-text-opacity: 1;
  color: rgb(0 150 255 / var(--tw-text-opacity))
}

.text-status-web {
  color: #464646a4
}

.text-white {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity))
}

.underline {
  text-decoration-line: underline
}

.antialiased {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale
}

.opacity-100 {
  opacity: 1
}

.opacity-20 {
  opacity: 0.2
}

.opacity-25 {
  opacity: 0.25
}

.shadow-lg {
  --tw-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);
  --tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color), 0 4px 6px -4px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
}

.shadow-md {
  --tw-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
  --tw-shadow-colored: 0 4px 6px -1px var(--tw-shadow-color), 0 2px 4px -2px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
}

.shadow-sm {
  --tw-shadow: 0 1px 2px 0 rgb(0 0 0 / 0.05);
  --tw-shadow-colored: 0 1px 2px 0 var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
}

.outline-none {
  outline: 2px solid transparent;
  outline-offset: 2px
}

.grayscale {
  --tw-grayscale: grayscale(100%);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow)
}

.invert {
  --tw-invert: invert(100%);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow)
}

.sepia {
  --tw-sepia: sepia(100%);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow)
}

.filter {
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow)
}

.transition {
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-backdrop-filter;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter, -webkit-backdrop-filter;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms
}

.hover\:border:hover {
  border-width: 1px
}

.hover\:border-solid:hover {
  border-style: solid
}

.hover\:border-opacity-100:hover {
  --tw-border-opacity: 1
}

.hover\:bg-gray-100:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(243 244 246 / var(--tw-bg-opacity))
}

.hover\:bg-gray-400:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(156 163 175 / var(--tw-bg-opacity))
}

.hover\:bg-mainOrange:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(238 89 25 / var(--tw-bg-opacity))
}

.hover\:bg-orange-500:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(249 115 22 / var(--tw-bg-opacity))
}

.hover\:bg-red-600:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(220 38 38 / var(--tw-bg-opacity))
}

.hover\:text-gray-700:hover {
  --tw-text-opacity: 1;
  color: rgb(55 65 81 / var(--tw-text-opacity))
}

.hover\:text-gray-800:hover {
  --tw-text-opacity: 1;
  color: rgb(31 41 55 / var(--tw-text-opacity))
}

.hover\:text-red-600:hover {
  --tw-text-opacity: 1;
  color: rgb(220 38 38 / var(--tw-text-opacity))
}

.hover\:text-white:hover {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity))
}

.hover\:shadow-md:hover {
  --tw-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
  --tw-shadow-colored: 0 4px 6px -1px var(--tw-shadow-color), 0 2px 4px -2px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
}

.hover\:shadow-sm:hover {
  --tw-shadow: 0 1px 2px 0 rgb(0 0 0 / 0.05);
  --tw-shadow-colored: 0 1px 2px 0 var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
}

.focus\:border-gray-200:focus {
  --tw-border-opacity: 1;
  border-color: rgb(229 231 235 / var(--tw-border-opacity))
}

.focus\:outline-none:focus {
  outline: 2px solid transparent;
  outline-offset: 2px
}

.focus\:ring-0:focus {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(0px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)
}

.focus\:ring-gray-200:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(229 231 235 / var(--tw-ring-opacity))
}

.group:hover .group-hover\:block {
  display: block
}

@media (prefers-color-scheme: dark) {
  .dark\:border-gray-300 {
    --tw-border-opacity: 1;
    border-color: rgb(209 213 219 / var(--tw-border-opacity))
  }

  .dark\:bg-gray-200 {
    --tw-bg-opacity: 1;
    background-color: rgb(229 231 235 / var(--tw-bg-opacity))
  }

  .dark\:text-gray-600 {
    --tw-text-opacity: 1;
    color: rgb(75 85 99 / var(--tw-text-opacity))
  }

  .dark\:text-gray-700 {
    --tw-text-opacity: 1;
    color: rgb(55 65 81 / var(--tw-text-opacity))
  }

  .dark\:placeholder-gray-400::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgb(156 163 175 / var(--tw-placeholder-opacity))
  }

  .dark\:focus\:border-gray-300:focus {
    --tw-border-opacity: 1;
    border-color: rgb(209 213 219 / var(--tw-border-opacity))
  }

  .dark\:focus\:ring-gray-300:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(209 213 219 / var(--tw-ring-opacity))
  }
}

@media (min-width: 768px) {
  .md\:grid-cols-8 {
    grid-template-columns: repeat(8, minmax(0, 1fr))
  }
}

@media (min-width: 1024px) {
  .lg\:text-\[1\.7rem\] {
    font-size: 1.7rem
  }
}

@media (min-width: 1280px) {
  .xl\:grid-cols-12 {
    grid-template-columns: repeat(12, minmax(0, 1fr))
  }
}