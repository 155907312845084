.fullscreen-modal .ant-modal {
    height: 90vh;
    width: 90vw;
    margin: 0;
    top: 0;
}



.fullscreen-modal .ant-modal .ant-modal-content{
    overflow-y: hidden;
    height: 100vh;
    width: 100vw;
    margin: 0;
    top: 0;  
}


.fullscreen-modal .ant-modal .ant-modal-content .ant-modal-body .col-scrollable{
    overflow-y: auto;
    height: 85vh;
}

.medium-modal .ant-modal {
    height: 90vh !important;
    width: 94vw !important;
    top: 4vh;
}

.medium-modal .ant-modal .ant-modal-content .ant-modal-body .col-scrollable{
    overflow-y: auto;
    height: 75vh;
}

.default .ant-modal {
    height: 90vh !important;
    top: 4vh;
}

.default .ant-modal .ant-modal-content .ant-modal-body{

    height: 100%;
}

.ant-input[disabled]{
    color: rgb(0 0 0 / 62%) !important;
}

.ant-select-disabled.ant-select:not(.ant-select-customize-input) .ant-select-selector {
    color: rgb(0 0 0 / 62%) !important; 
}

